import React from 'react';
import './App.css';
import NavBar from './nav-routes/NavBar';
import myInfo from './myInformation.json';
import InfoContext from './InfoContext';
import Routes from './nav-routes/Routes';
import Footer from './components/Footer';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';

const myTheme = createMuiTheme({
	palette : {
		primary   : {
			main : '#039be5'
		},
		secondary : {
			main : '#f48fb1'
		}
	}
});

function App() {
	return (
		<InfoContext.Provider value={myInfo}>
			<ThemeProvider theme={myTheme}>
				<BrowserRouter>
					<NavBar />
					<Routes />
					<Footer />
				</BrowserRouter>
			</ThemeProvider>
		</InfoContext.Provider>
	);
}

export default App;
